import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";

function MainContainer(props) {
  // Main Container Section Screen States
  const [isOnMainContainerScreen, setIsOnMainContainerScreen] = useState(false);
  const [navbarClass, setNavbarClass] = useState("");
  const [bannerElementClass, setBannerElementClass] = useState("");
  const [sloganH1Class, setSloganH1Class] = useState("");
  const [sloganH3Class, setSloganH3Class] = useState("");

  const handleWayPointEnterMainContainerSection = () => {
    setIsOnMainContainerScreen(true);
    setTimeout(() => {
      setNavbarClass("animateFadeIn");
    }, 400);
    setTimeout(() => {
      setBannerElementClass("animateFadeIn");
    }, 1000);
    setTimeout(() => {
      setSloganH1Class("animateSlideUp");
    }, 1600);
    setTimeout(() => {
      setSloganH3Class("animateSlideUp");
    }, 1800);
  };

  return (
    <div>
      <Waypoint onEnter={handleWayPointEnterMainContainerSection}>
        <div>
          <div
            id="nav-container"
            className={isOnMainContainerScreen && navbarClass}
            style={{ opacity: 0 }}
          >
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/">
                <img className="img-responsive" src="/css/images/logo.png" />
              </Link>

              <div
                className="collapse navbar-collapse justify-content-end"
                id="collapsibleNavbar"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a href="#service-section" className="nav-link">
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#news-and-updates-section" className="nav-link">
                      News and Updates
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#security-section" className="nav-link">
                      Security Commitment
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#portfolio-section" className="nav-link">
                      Portfolio
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#contact-section" className="nav-link">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

          {/* <!-- Banner Section --> */}
          <div className="home-banner">
            <div id="particle-container">
              <div id="banner-element" className="">
                <div
                  className={
                    isOnMainContainerScreen
                      ? "banner-element " + bannerElementClass
                      : "banner-element"
                  }
                  style={{ opacity: 0 }}
                ></div>
              </div>
              <div className="slogan-container">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="slogan text-center">
                      <h1
                        id="slogan-h1"
                        className={isOnMainContainerScreen && sloganH1Class}
                        style={{ opacity: 0 }}
                      >
                        Innovate Your
                        <br />
                        Financial Firm
                      </h1>
                      <h3
                        id="slogan-h3"
                        className={
                          isOnMainContainerScreen
                            ? "sub-head center text-center " + sloganH3Class
                            : "sub-head center text-center"
                        }
                        style={{ opacity: 0 }}
                      >
                        We provide full service software solutions to meet your
                        business’s needs{" "}
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-6 mobile-background"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Waypoint>
    </div>
  );
}

export default MainContainer;
