import React, { useEffect, useState, useRef } from "react";
import MainContainer from "./components/mainContainerSection/MainContainer";
import Services from "./components/servicesSection/Services";
import NewsAndUpdates from "./components/newsAndUpdatesSection/NewsAndUpdates";
import Portfolio from "./components/portfolioSection/Porfolio";
import ContactUs from "./components/contactUsSection/ContactUs";
import SecurityCommitment from "./components/securityCommitmentSection/Security";
import Footer from "./components/footerSection/Footer";

function Home(props) {
  const [windowLocation, setWindowLocation] = useState(window.location.href);

  const servicesRef = useRef(null);
  const newsUpdatesRef = useRef(null);
  const portfolioRef = useRef(null);
  const contactUsRef = useRef(null);
  const securityCommitmentRef = useRef(null);

  const executeServiceScroll = () => servicesRef.current.scrollIntoView();
  const executeNewsUpdatesScroll = () =>
    newsUpdatesRef.current.scrollIntoView();
  const executePortfolioScroll = () => portfolioRef.current.scrollIntoView();
  const executeContactUsScroll = () => contactUsRef.current.scrollIntoView();
  const executeSecurityCommitmentScroll = () =>
    securityCommitmentRef.current.scrollIntoView();

  useEffect(() => {
    props.setProgress(100);
    document.title = props.title || "";
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setWindowLocation(window.location.href);

    let section = windowLocation.split("#")[1]
      ? windowLocation.split("#")[1]
      : "";

    switch (section) {
      case "service-section":
        executeServiceScroll();
        break;
      case "news-and-updates-section":
        executeNewsUpdatesScroll();
        break;
      case "portfolio-section":
        executePortfolioScroll();
        break;
      case "contact-section":
        executeContactUsScroll();
        break;
      case "security-section":
        executeSecurityCommitmentScroll();
        break;
      default:
    }
  }, []);

  return (
    <div>
      <div className="page page-home" id="main-container">
        <MainContainer />
        <Services thisRef={servicesRef} />
        <NewsAndUpdates thisRef={newsUpdatesRef} />
        <SecurityCommitment thisRef={securityCommitmentRef} />
        <Portfolio thisRef={portfolioRef} />
        <ContactUs thisRef={contactUsRef} />
        <Footer />
        {/* <div ref={contactUsRef}> Helloo</div> */}
      </div>
    </div>
  );
}

export default Home;
