import React, { useState, useEffect } from "react";
import { Waypoint } from "react-waypoint";

function Security(props) {
  // Security Section Screen
  const [isOnSecurityScreen, setIsOnSecurityScreen] = useState(false);
  const [p1Class, setP1Class] = useState("");
  const [p2Class, setP2Class] = useState("");
  const [p3Class, setP3Class] = useState("");
  const [p4Class, setP4Class] = useState("");
  const [p5Class, setP5Class] = useState("");
  const [p6Class, setP6Class] = useState("");
  const [p7Class, setP7Class] = useState("");
  const [prsClass, setPrsClass] = useState("");

  const handleWayPointEnterSecuritySection = () => {
    setIsOnSecurityScreen(true);
    if (!isSecurityClicked) {
      setTimeout(() => {
        setP1Class("animateSlideUp");
      }, 0);
      setTimeout(() => {
        setP2Class("animateSlideUp");
      }, 200);
      setTimeout(() => {
        setP3Class("animateSlideUp");
      }, 400);
      setTimeout(() => {
        setP4Class("animateSlideUp");
      }, 600);
      setTimeout(() => {
        setP5Class("animateSlideUp");
      }, 800);
      setTimeout(() => {
        setP6Class("animateSlideUp");
      }, 1000);
      setTimeout(() => {
        setP7Class("animateSlideUp");
      }, 1200);
    }
  };

  // Handling Security Button
  const [isSecurityClicked, setIsSecurityClicked] = useState(false);
  const [pHeadingClass, setPHeadingClass] = useState("");
  const [selectedTab, setSelectedTab] = useState("");

  const handleSecurityClickAnimation = () => {
    if (!isSecurityClicked) {
      setTimeout(() => {
        setPHeadingClass(" animateSlideLeft");
      }, 0);
      setTimeout(() => {
        setP1Class(p1Class + " animateSlideLeft");
      }, 200);
      setTimeout(() => {
        setP2Class(p2Class + " animateSlideLeft");
      }, 400);
      setTimeout(() => {
        setP3Class(p3Class + " animateSlideLeft");
      }, 600);
      setTimeout(() => {
        setP4Class(p4Class + " animateSlideLeft");
      }, 800);
      setTimeout(() => {
        setP5Class(p5Class + " animateSlideLeft");
      }, 1000);
      setTimeout(() => {
        setP6Class(p6Class + " animateSlideLeft");
      }, 1200);
      setTimeout(() => {
        setP7Class(p7Class + " animateSlideLeft");
      }, 1400);
      setTimeout(function () {
        setIsSecurityClicked(true);
        setPrsClass("animateSlideUp");
      }, 1800);
    }
  };

  // Handling Security Button Content
  const [p1ContentClass, setP1ContentClass] = useState("content-item inactive");
  const [p2ContentClass, setP2ContentClass] = useState("content-item inactive");
  const [p3ContentClass, setP3ContentClass] = useState("content-item inactive");
  const [p4ContentClass, setP4ContentClass] = useState("content-item inactive");
  const [p5ContentClass, setP5ContentClass] = useState("content-item inactive");
  const [p6ContentClass, setP6ContentClass] = useState("content-item inactive");
  const [p7ContentClass, setP7ContentClass] = useState("content-item inactive");

  useEffect(() => {
    if (selectedTab === 1) {
      setP1ContentClass("content-item active");
    } else {
      setP1ContentClass("content-item inactive");
    }

    if (selectedTab === 2) {
      setP2ContentClass("content-item active");
    } else {
      setP2ContentClass("content-item inactive");
    }

    if (selectedTab === 3) {
      setP3ContentClass("content-item active");
    } else {
      setP3ContentClass("content-item inactive");
    }

    if (selectedTab === 4) {
      setP4ContentClass("content-item active");
    } else {
      setP4ContentClass("content-item inactive");
    }

    if (selectedTab === 5) {
      setP5ContentClass("content-item active");
    } else {
      setP5ContentClass("content-item inactive");
    }

    if (selectedTab === 6) {
      setP6ContentClass("content-item active");
    } else {
      setP6ContentClass("content-item inactive");
    }

    if (selectedTab === 7) {
      setP7ContentClass("content-item active");
    } else {
      setP7ContentClass("content-item inactive");
    }
  }, [selectedTab]);

  return (
    <div ref={props.thisRef}>
      {/* <!-- Security Section --> */}
      <Waypoint onEnter={handleWayPointEnterSecuritySection}>
        <div id="security-section" className="portfolio-content">
          <div className="grd-row portfolio-container grd-align-center">
            <div id="p-l-side" className="sidebar grd-cell-none">
              <h2
                id="p-headingt"
                className={"text-center set-position " + pHeadingClass}
              >
                Commitment to Security
              </h2>
              <ul id="p-list" className="side-menu">
                <li
                  id="p1"
                  className={
                    isOnSecurityScreen
                      ? "set-position " + p1Class
                      : "set-position"
                  }
                >
                  <a
                    onClick={() => {
                      handleSecurityClickAnimation();
                      setSelectedTab(1);
                    }}
                  >
                    Meet Industry Compliance Standards
                  </a>
                </li>
                <li
                  id="p2"
                  className={
                    isOnSecurityScreen
                      ? "set-position " + p2Class
                      : "set-position"
                  }
                >
                  <a
                    onClick={() => {
                      handleSecurityClickAnimation();
                      setSelectedTab(2);
                    }}
                  >
                    Strengthening Security with Penetration Testing
                  </a>
                </li>
                <li
                  id="p3"
                  className={
                    isOnSecurityScreen
                      ? "set-position " + p3Class
                      : "set-position"
                  }
                >
                  <a
                    onClick={() => {
                      handleSecurityClickAnimation();
                      setSelectedTab(3);
                    }}
                  >
                    Guaranteeing Data Integrity with WORM Compliance Ensuring
                  </a>
                </li>
                <li
                  id="p4"
                  className={
                    isOnSecurityScreen
                      ? "set-position " + p4Class
                      : "set-position"
                  }
                >
                  <a
                    onClick={() => {
                      handleSecurityClickAnimation();
                      setSelectedTab(4);
                    }}
                  >
                    Business Continuity with BCP Compliance
                  </a>
                </li>
                <li
                  id="p5"
                  className={
                    isOnSecurityScreen
                      ? "set-position " + p5Class
                      : "set-position"
                  }
                >
                  <a
                    onClick={() => {
                      handleSecurityClickAnimation();
                      setSelectedTab(5);
                    }}
                  >
                    The Ultimate Hybrid Solution
                  </a>
                </li>
                <li
                  id="p6"
                  className={
                    isOnSecurityScreen
                      ? "set-position " + p6Class
                      : "set-position"
                  }
                >
                  <a
                    onClick={() => {
                      handleSecurityClickAnimation();
                      setSelectedTab(6);
                    }}
                  >
                    Why Choose Us?
                  </a>
                </li>
              </ul>
            </div>
            <div id="p-r-side" className="grd-cell">
              <div
                id="p-r-side-c"
                className={
                  isSecurityClicked
                    ? "tab-contents " + prsClass
                    : "tab-contents hidden"
                }
              >
                <div className={p1ContentClass}>
                  <h3>Meet Industry Compliance Standards</h3>
                  <div className="content">
                    <label className="sub-header">
                      We take compliance very seriously, which is why we’ve
                      earned both SOC 2 Type 1 and SOC 2 Type 2 certifications:
                    </label>
                    <div className="row">
                      <div className="col-md-9">
                        <ul>
                          <li>
                            Type 1 ensures that our systems and controls are
                            designed effectively to meet stringent security
                            requirements.
                          </li>
                          <li>
                            Type 2 shows that we maintain these controls over
                            time, ensuring consistent performance.
                          </li>
                        </ul>
                      </div>
                      <p>
                        We’ve also partnered with <b>Secureframe</b>, a leading
                        platform for compliance automation, to stay ahead of
                        evolving regulations. Secureframe helps us ensure our
                        processes are always current, scalable, and audit-ready,
                        saving time and reducing risks for you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={p2ContentClass}>
                  <h3>Strengthening Security with Penetration Testing</h3>
                  <div className="content">
                    <p>
                      To proactively protect against potential threats, we
                      conduct regular penetration tests. These tests simulate
                      real-world cyberattacks, allowing us to identify and
                      address vulnerabilities before they can be exploited. This
                      isn’t a one-time effort—it’s an ongoing commitment to
                      ensuring your data remains safe and secure. With us, you
                      can rest easy knowing we’re always ahead of potential
                      threats.
                    </p>
                  </div>
                </div>
                <div className={p3ContentClass}>
                  <h3>Guaranteeing Data Integrity with WORM Compliance</h3>
                  <div className="content">
                    <p>
                      For industries that rely on unalterable record-keeping,
                      such as finance, healthcare, or legal, we’ve implemented
                      WORM-compliant (Write Once, Read Many) storage. This
                      ensures that once data is written, it can’t be changed or
                      tampered with, meeting critical regulatory requirements
                      for record retention. With this level of data integrity,
                      you can trust that your most important information is safe
                      and reliable for as long as needed.
                    </p>
                  </div>
                </div>
                <div className={p4ContentClass}>
                  <h3>Ensuring Business Continuity with BCP Compliance</h3>
                  <div className="content">
                    <p>
                      Downtime isn’t an option. Our Business Continuity Planning
                      (BCP) ensures that we’re always operational, even during
                      unexpected disruptions like natural disasters,
                      cyberattacks, or system failures. We’ve developed, tested,
                      and refined our continuity plans to minimize downtime and
                      ensure your business is never left vulnerable. With us,
                      you get a partner who’s always prepared for the
                      unexpected.
                    </p>
                  </div>
                </div>
                <div className={p5ContentClass}>
                  <h3>The Ultimate Hybrid Solution</h3>
                  <div className="content">
                    <p>
                      SoftwareAlgo seamlessly integrates into both Public Cloud
                      and On-Premise environments, offering unmatched
                      flexibility and performance. Whether you’re leveraging the
                      scalability of the cloud or ensuring compliance and
                      control with on-premise systems, SoftwareAlgo adapts to
                      meet your unique needs.
                    </p>
                    <label className="sub-header">
                      With secure, scalable, and cost-efficient integration,
                      SoftwareAlgo empowers your business to thrive in any IT
                      landscape. Optimize your operations and future-proof your
                      infrastructure with a solution built for versatility and
                      growth.
                    </label>
                    <div className="row">
                      <div className="col-md-4">
                        <ul>
                          <li>AWS</li>
                          <li>GCP</li>
                          <li>Docker</li>
                          <li>Insider Trading</li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <ul>
                          <li>Azure</li>
                          <li>On-Prem</li>
                          <li>Mini Manipulation</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={p6ContentClass}>
                  <h3>Why Choose Us?</h3>
                  <div className="content">
                    <p>
                      When you work with SoftwareAlgo Solutions you’re not just
                      getting a service provider—you’re gaining a trusted
                      partner. Our commitment to security and compliance is
                      built into everything we do.
                    </p>
                    <label className="sub-header">We combine:</label>
                    <div className="row">
                      <div className="col-md-6">
                        <ul>
                          <li>
                            Industry-leading SOC 2 Type 1 and Type 2
                            certifications.
                          </li>
                          <li>
                            Ongoing penetration testing for proactive
                            protection.
                          </li>
                          <li>
                            Reliable WORM compliance for tamper-proof data
                            storage.{" "}
                          </li>
                          <li>
                            Comprehensive business continuity plans for
                            resilience.
                          </li>
                          <li>
                            The power of Secureframe to keep compliance seamless
                            and scalable.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Waypoint>
    </div>
  );
}

export default Security;
