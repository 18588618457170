import React, { useState } from "react";
import { Waypoint } from "react-waypoint";

function Services(props) {
  // Service Header Section Screen States
  const [isOnServiceHeaderScreen, setIsOnServiceHeaderScreen] = useState(false);
  const [serviceH1Class, setServiceH1Class] = useState("");

  const handleWayPointEnterServiceHeaderSection = () => {
    setIsOnServiceHeaderScreen(true);
    setTimeout(() => {
      setServiceH1Class("animateSlideUp");
    }, 500);
  };

  // Service List Section Screen States
  const [isOnServiceListScreen, setIsOnServiceListScreen] = useState(false);
  const [supportClass, setSupportClass] = useState("");
  const [deploymentClass, setDeploymentClass] = useState("");
  const [integrationClass, setIntegrationClass] = useState("");
  const [documentationClass, setDocumentationClass] = useState("");
  const [customizationClass, setCustomizationClass] = useState("");

  const handleWayPointEnterServiceListSection = () => {
    setIsOnServiceListScreen(true);
    setTimeout(() => {
      setSupportClass("animateSlideUp");
    }, 0);
    setTimeout(() => {
      setDeploymentClass("animateSlideUp");
    }, 300);
    setTimeout(() => {
      setIntegrationClass("animateSlideUp");
    }, 600);
    setTimeout(() => {
      setDocumentationClass("animateSlideUp");
    }, 900);
    setTimeout(() => {
      setCustomizationClass("animateSlideUp");
    }, 1200);
  };

  return (
    <div ref={props.thisRef}>
      {/* <!-- Services Section --> */}
      <div id="service-section" className="service-content">
        <Waypoint onEnter={handleWayPointEnterServiceHeaderSection}>
          <div id="service-section" className="service-section">
            <div
              id="service-h1"
              className={
                isOnServiceHeaderScreen
                  ? "service-container " + serviceH1Class
                  : "service-container"
              }
            >
              <div className="service-heading">
                <h2 className="text-center">Our Services</h2>
                <p className="text-center t-width-md center">
                  Software Algo Solutions has over 20 years’ experience in
                  building software applications for the financial industry. Our
                  primary offering is <i>SAS Clearing</i>, a complete end-to-end
                  processing and self-clearing solution, built for the cloud and
                  ready to deploy. Whether you are looking to build an internal
                  books and records accounting system, or a full function
                  self-clearing system, SAS Clearing has the solution for your
                  business. Our clients include Broker Dealers, IRA Custodians,
                  Self-Clearing and Correspondent Clearing Brokers and digital
                  asset providers. Our solution currently offers processing in
                  Equities, Options, Digital Assets/Currency and soon to include
                  Fixed Income and Treasury.
                </p>
              </div>
            </div>
          </div>
        </Waypoint>

        <Waypoint onEnter={handleWayPointEnterServiceListSection}>
          <div className="service-list">
            <div className="services-item-container flex">
              <div className="col services-item">
                <div
                  id="support"
                  className={
                    isOnServiceListScreen
                      ? "inner-container " + supportClass
                      : "inner-container"
                  }
                >
                  <div className="icon"></div>
                  <h3>Support</h3>
                  <p>
                    Our team of developers have extensive experience providing
                    support after implementation of our software. Our support
                    services are tailored to our clients’ unique needs, and each
                    client is assigned a dedicated team member for all their
                    support requirements.
                  </p>
                </div>
              </div>
              <div className="col services-item">
                <div
                  id="deployment"
                  className={
                    isOnServiceListScreen
                      ? "inner-container " + deploymentClass
                      : "inner-container"
                  }
                >
                  <div className="icon icon-web-app"></div>
                  <h3>Deployment</h3>
                  <p>
                    Our approach to application deployment is well-coordinated
                    and involves constant collaboration from stakeholders,
                    external partners, and third-party service providers. Trust
                    our team leads to manage deployments from planning,
                    configuration, integration through to deployment.
                  </p>
                </div>
              </div>
              <div className="col services-item">
                <div
                  id="integration"
                  className={
                    isOnServiceListScreen
                      ? "inner-container " + integrationClass
                      : "inner-container"
                  }
                >
                  <div className="icon icon-desktop-app"></div>
                  <h3>Integration</h3>
                  <p>
                    SAS offers comprehensive integration services. From
                    consultation, all the way to design and implementation of
                    solutions of your business needs.
                  </p>
                </div>
              </div>
              <div className="col services-item">
                <div
                  id="documentation"
                  className={
                    isOnServiceListScreen
                      ? "inner-container " + documentationClass
                      : "inner-container"
                  }
                >
                  <div className="icon icon-documentation"></div>
                  <h3>Documentation</h3>
                  <p>
                    SAS’ experienced team builds and maintains high-quality
                    technical documentation for all our applications. We work
                    collaboratively with our clients to generate comprehensive
                    documentation and manuals for their team to better
                    understand the functionality and capability of our systems.
                  </p>
                </div>
              </div>
              <div className="col services-item">
                <div
                  id="customization"
                  className={
                    isOnServiceListScreen
                      ? "inner-container " + customizationClass
                      : "inner-container"
                  }
                >
                  <div className="icon icon-system-maintenance"></div>
                  <h3>Customization</h3>
                  <p>
                    SAS builds applications that fully cover the unique and
                    specific business functions of our clients. Customized
                    software created by our developers will help your business
                    to deliver best of breed tailored customer experiences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Waypoint>
      </div>
    </div>
  );
}

export default Services;
